import { GetStaticProps, NextPage } from "next";
import Head from "next/head";
import React from "react";
import fetchStrapiPage from "../lib/fetchStrapiPage";
import PageCollection from "../strapi_types/PageCollection";

interface HomeProps {
  pageContent: PageCollection;
}

const PageNotFound: NextPage = () => {
  return (
    <div>
      <Head>
        <title>Barbershop Tatarević - Stranica ne postoji</title>
        <meta name="robots" content="noindex" />
      </Head>
      <h1>Stranica ne postoji</h1>
    </div>
  );
};

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  const data = await fetchStrapiPage("home");
  return {
    props: {
      pageContent: data,
    },
  };
};

export default PageNotFound;
